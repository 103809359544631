import { useInView } from 'react-intersection-observer';

import './project.css'

export interface IImage {
	highRes: string;
	lowRes: string;
	highResW: string;
	lowResW: string;
	placeholder: string;
	height: number;
	width: number;
}

export interface ISlide {
	name: string,
	images: IImage[];
	copy: string[];
}

export interface IProjectProps {
	slide: ISlide;
}

export function parseName(path: string): string {
	const s = path.lastIndexOf("/")
	const filename = path.substring(s + 1);
	return filename.substring(0, filename.indexOf("."));
}

export interface IPictureProps {
	image: IImage;
	useHighRes: boolean;
	className: string;
	usePlaceHolder?: boolean;
}

/*
För lazy loading se
https://github.com/thebuilder/react-intersection-observer/blob/main/docs/Recipes.md#lazy-image-load
*/

export function Picture(props: IPictureProps) {

	const { ref, inView } = useInView({
		triggerOnce: true,
		rootMargin: '200px 0px'
	});

	return (
		<div ref={ref} className="position-relative" style={{paddingBottom: `${(props.image.height / props.image.width) * 100}%`}}>
			<picture>
				{!inView && props.usePlaceHolder
				? <>
					<source srcSet={`${props.image.placeholder}`} type="image/png"/>

					<img className={"position-absolute w-100 h-100 " + props.className} src={props.image.placeholder} height={props.image.height} width={props.image.width} alt=""/>		
				</>
				: props.useHighRes 
				? <>
					<source media="(max-width: 767px)" srcSet={`${props.image.highResW}  2x, ${props.image.lowResW} 1x`} type="image/webp"/>
					<source media="(max-width: 767px)" srcSet={`${props.image.highRes}  2x, ${props.image.lowRes} 1x`} type="image/jpeg"/>

					<source srcSet={`${props.image.highResW}`} type="image/webp"/>

					<img className={"position-absolute w-100 h-100 " + props.className} src={props.image.highRes} height={props.image.height} width={props.image.width} alt=""/>
				
				</>
				: <>	
					<source srcSet={`${props.image.highResW}  2x, ${props.image.lowResW} 1x`} type="image/webp"/>
					<source srcSet={`${props.image.highRes}  2x, ${props.image.lowRes} 1x`} type="image/jpeg"/>

					<img className={"position-absolute w-100 h-100 " + props.className} src={props.image.lowResW} height={props.image.height} width={props.image.width} alt=""/>
				</>}
			</picture>
		</div>
	);
}
