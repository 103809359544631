import { parseName, Picture } from './Project';
import './project.css'

// Sibyllegatan
import s24_1 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_240.jpg'
import s24_2 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348.jpg'
import s24_3 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_400.jpg'
import s24_4 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350.jpg'
import s24_5 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_263.jpg'
import s24_6 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_297_2.jpg'
import s24_7 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_354.jpg'
import s24_8 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_387.jpg'
import s24_9 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_040.jpg'
import s24_10 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_204.jpg'
import s24_11 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_443.jpg'
import s24_12 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_168.jpg'
import s24_13 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1.jpg'
import s24_14 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_411_1.jpg'
import s24_15 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_258_1.jpg'
import s24_16 from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_053_1.jpg'

// Sibyllegatan webp
import s24_1w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_240.webp'
import s24_2w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348.webp'
import s24_3w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_400.webp'
import s24_4w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350.webp'
import s24_5w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_263.webp'
import s24_6w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_297_2.webp'
import s24_7w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_354.webp'
import s24_8w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_387.webp'
import s24_9w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_040.webp'
import s24_10w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_204.webp'
import s24_11w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_443.webp'
import s24_12w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_168.webp'
import s24_13w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1.webp'
import s24_14w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_411_1.webp'
import s24_15w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_258_1.webp'
import s24_16w from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_053_1.webp'

// Sibyllegatan Low Res
import s24_1_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_240_LR.jpg'
import s24_2_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348_LR.jpg'
import s24_3_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_400_LR.jpg'
import s24_4_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350_LR.jpg'
import s24_5_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_263_LR.jpg'
import s24_6_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_297_2_LR.jpg'
import s24_7_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_354_LR.jpg'
import s24_8_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_387_LR.jpg'
import s24_9_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_040_LR.jpg'
import s24_10_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_204_LR.jpg'
import s24_11_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_443_LR.jpg'
import s24_12_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_168_LR.jpg'
import s24_13_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1_LR.jpg'
import s24_14_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_411_1_LR.jpg'
import s24_15_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_258_1_LR.jpg'
import s24_16_LR from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_053_1_LR.jpg'

// Sibyllegatan Low Res webp
import s24_1_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_240_LR.webp'
import s24_2_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348_LR.webp'
import s24_3_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_400_LR.webp'
import s24_4_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350_LR.webp'
import s24_5_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_263_LR.webp'
import s24_6_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_297_2_LR.webp'
import s24_7_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_354_LR.webp'
import s24_8_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_387_LR.webp'
import s24_9_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_040_LR.webp'
import s24_10_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_204_LR.webp'
import s24_11_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_443_LR.webp'
import s24_12_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_168_LR.webp'
import s24_13_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1_LR.webp'
import s24_14_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_411_1_LR.webp'
import s24_15_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_258_1_LR.webp'
import s24_16_LRw from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_053_1_LR.webp'

// Sibyllegatan Low Res webp
import s24_1_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_240_ph.png'
import s24_2_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348_ph.png'
import s24_3_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_400_ph.png'
import s24_4_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350_ph.png'
import s24_5_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_263_ph.png'
import s24_6_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_297_2_ph.png'
import s24_7_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_354_ph.png'
import s24_8_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_387_ph.png'
import s24_9_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_040_ph.png'
import s24_10_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_204_ph.png'
import s24_11_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_443_ph.png'
import s24_12_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_168_ph.png'
import s24_13_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1_ph.png'
import s24_14_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_411_1_ph.png'
import s24_15_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_258_1_ph.png'
import s24_16_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_053_1_ph.png'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function ProjectS24() {
	const slide = {
			images: [
				{ name: parseName(s24_1), highRes: s24_1, lowRes: s24_1_LR , highResW: s24_1w, lowResW: s24_1_LRw, placeholder: s24_1_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_2), highRes: s24_2, lowRes: s24_2_LR , highResW: s24_2w, lowResW: s24_2_LRw, placeholder: s24_2_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_3), highRes: s24_3, lowRes: s24_3_LR , highResW: s24_3w, lowResW: s24_3_LRw, placeholder: s24_3_ph, height: 1598, width: 2400 },
				{ name: parseName(s24_4), highRes: s24_4, lowRes: s24_4_LR , highResW: s24_4w, lowResW: s24_4_LRw, placeholder: s24_4_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_5), highRes: s24_5, lowRes: s24_5_LR , highResW: s24_5w, lowResW: s24_5_LRw, placeholder: s24_5_ph, height: 1545, width: 2400 },
				{ name: parseName(s24_6), highRes: s24_6, lowRes: s24_6_LR , highResW: s24_6w, lowResW: s24_6_LRw, placeholder: s24_6_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_7), highRes: s24_7, lowRes: s24_7_LR , highResW: s24_7w, lowResW: s24_7_LRw, placeholder: s24_7_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_8), highRes: s24_8, lowRes: s24_8_LR , highResW: s24_8w, lowResW: s24_8_LRw, placeholder: s24_8_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_9), highRes: s24_9, lowRes: s24_9_LR , highResW: s24_9w, lowResW: s24_9_LRw, placeholder: s24_9_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_10), highRes: s24_10,lowRes: s24_10_LR, highResW: s24_10w,lowResW: s24_10_LRw, placeholder: s24_10_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_11), highRes: s24_11,lowRes: s24_11_LR, highResW: s24_11w,lowResW: s24_11_LRw, placeholder: s24_11_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_12), highRes: s24_12, lowRes: s24_12_LR, highResW: s24_12w, lowResW: s24_12_LRw, placeholder: s24_12_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_13), highRes: s24_13, lowRes: s24_13_LR, highResW: s24_13w, lowResW: s24_13_LRw, placeholder: s24_13_ph, height: 2400, width: 1920 },
				{ name: parseName(s24_14), highRes: s24_14, lowRes: s24_14_LR, highResW: s24_14w, lowResW: s24_14_LRw, placeholder: s24_14_ph, height: 2400, width: 2169 },
				{ name: parseName(s24_15), highRes: s24_15, lowRes: s24_15_LR, highResW: s24_15w, lowResW: s24_15_LRw, placeholder: s24_15_ph, height: 1609, width: 2401 },
				{ name: parseName(s24_16), highRes: s24_16, lowRes: s24_16_LR, highResW: s24_16w, lowResW: s24_16_LRw, placeholder: s24_16_ph, height: 2400, width: 1920 },
			],
			copy: ["Commercial Space • Stockholm, 2020 • Photography by Wavy Studio"]
		}

	let location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location])

	return (
		<div className="project-layout flex-layout overflow-hidden mb-5 pb-5">
			<div className="flex-layout container-xxl mt-md-4 px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[0]} useHighRes={true} className="" />
					</div>
				</div>
			</div>
			<div className="flex-layout container-fluid px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[4]} useHighRes={true} className="" />
					</div>
				</div>
			</div>
			<div className="flex-layout container-xxl px-0 px-sm-3">
				<div className="row flex-column flex-md-row">
					<div className="col-12 col-md-6 mb-3 mb-md-0">
						<Picture image={slide.images[12]} useHighRes={false} className="" usePlaceHolder={true} />
					</div>
					<div className="col-12 col-md-6">
						<Picture image={slide.images[6]} useHighRes={false} className="" usePlaceHolder={true} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[3]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[1]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-fluid px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[2]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-xxl px-0 px-sm-3">
				<div className="row flex-column flex-md-row">
					<div className="col-12 col-md-6 mb-3 mb-md-0">
						<Picture image={slide.images[15]} useHighRes={false} className="" usePlaceHolder={true} />
					</div>
					<div className="col-12 col-md-6">
						<Picture image={slide.images[8]} useHighRes={false} className="" usePlaceHolder={true} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[7]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[5]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-fluid px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[14]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-xxl px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[9]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[10]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>

				<div className="row flex-column flex-md-row">
					<div className="col-12 col-md-5 mb-3 mb-md-0 align-self-center">
						<Picture image={slide.images[11]} useHighRes={false} className="" usePlaceHolder={true} />
					</div>
					<div className="col-12 col-md-7">
						<Picture image={slide.images[13]} useHighRes={false} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
		</div>
	);
}
