import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Profile } from './components/Profile';
import { Projects } from './components/Projects';
import { ProjectS10 } from './components/S10';
import { ProjectS24 } from './components/S24';
import { ProjectSNR } from './components/SNR';

import Entry from './Entry';

export default function App() {

	useEffect(() => {
		document.title = "STUDIO NIKI ROLLOF"
	})

	return (
		<Router>
	  		<Entry></Entry>

			<Switch>
				<Route path='/studio'>
					<Profile />
				</Route>
				<Route path='/projects/s24'>
					<ProjectS24 />
				</Route>
				<Route path='/projects/s10'>
					<ProjectS10 />
				</Route>
				<Route path='/projects/snr'>
					<ProjectSNR />
				</Route>
				<Route path="/projects">
					<Projects />
				</Route>
			</Switch>

		</Router>
	);
}