import './profile.css'

export const Profile = () => {

	return (
		<div className="profile-container mb-5 pb-5">
			<div className="profile-content d-flex flex-column my-0 mx-auto">
				<div>ABOUT</div>
				<div className="d-flex mt-3">
					<div className="profile">
						<p className="mt-2">
							Studio Niki Rollof is an interior architecture practice operating across a variety of sectors 
							including private residences, commercial spaces as well as furniture design. 
							Based in Stockholm but working with clients worldwide, the studio aims to create interiors that 
							are timeless and sophisticated. 
							Our philosophy is that a space should be an extension of a personality or brand, and great 
							emphasis is being put into identifying and telling that story through design. 
							Working closely with talented architects, craftsmen and gallerists, the studio 
							operates in both small and large scale depending on the client’s needs. 
						</p>
					</div>
				</div>
				<div className="mt-4">OFFICE AND SHOWROOM</div>
				<div className="d-flex mt-3">
					<div className="profile">			  
						<p className="mt-2">
							Sibyllegatan 24 
							<br />
							114 42 Stockholm, Sweden  					
						</p>
						<p className="mt-2">
							Monday - Friday 09:00 - 18:00
						</p>
						<p className="mt-2">
							Please send us an <a className="ext-link" href="mailto:niki@nikirollof.com">email</a> to get in touch 
							or connect via <a className="ext-link" href="https://www.instagram.com/studionikirollof/">Instagram</a>.
						</p>				
					</div>
				</div>
			</div>		  
		</div>
	);
}
