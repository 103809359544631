import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useMediaQuery } from 'beautiful-react-hooks'; 

import { FadeIn } from './components/FadeIn';
import { FadeOut } from './components/FadeOut';
import { BackgroundImageSlider } from './backgroundImageSlider/BackgroundImageSlider';
import Header from './header/Header';

import img0 from './content/images/Bakgrund5.jpg'
import img0s from './content/images/Marding2/marding3957_LR.jpg'
import img05s from './content/images/Marding2/marding3954_LR.jpg'
import img1 from './content/images/Bakgrund6.jpg'
import img1s from './content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1_LR.jpg'
import img15s from './content/images/Sibyllegatan/2106_NR_Sibylleg_24_354_LR.jpg'
import img2 from './content/images/Bakgrund7.jpg'
import img2s from './content/images/Bakgrund7_LR.jpg'

import './header/header.css'
import './content/main.css'
import './components/fade.css'

export default function Entry() {
	const [showSlide, setShowSlide] = useState(true);
	let location = useLocation();
	const mobile = useMediaQuery('(max-width: 992px)'); // lg

	function setSliderCss(show: boolean) {
		var header = document.getElementById("header");
		if (header) {
			if (show) {
				header.classList.remove("fair");
			} else {
				header.classList.add("fair");
			}
			header.style.backgroundColor =  "transparent";
		}
	}

	useEffect(() => {
	  setShowSlide(location.pathname === "/");
	  setSliderCss(location.pathname === "/");
	}, [location.pathname])

	return (
		<>
		{ location.pathname === "/"
			?
			<>
			<FadeOut>
				<div className="niki-name vienna">STUDIO&nbsp;NIKI&nbsp;ROLLOF</div>
			</FadeOut>
			<FadeIn>
				<Header />
				{showSlide && 
					(mobile 
						? <BackgroundImageSlider images={[img0s, img05s, img1s, img15s, img2s]} duration={10} transition={2} />
						: <BackgroundImageSlider images={[img0, img1, img2]} duration={10} transition={2} />)
				}	  
			</FadeIn>
			</>
			: <Header />
		}
   		</>
	);
}
