import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

export default function Header() {
	const [showMobileMenu, setshowMobileMenu] = useState(false);
	let location = useLocation();

	useEffect(() => {
		displayMobileMenu(false);
	}, [location])

	function displayMobileMenu(show: boolean) {
		const menuLabel = document.getElementById("hamburger-menu");		
		const menuContainer = document.getElementById("menu-container");		
		if (menuLabel && menuContainer) {
			if (show) {
				menuLabel.classList.add("open")
				document.body.classList.add("overflow-hidden");
				menuContainer.classList.add("mobile-menu-show");
			} else {
				menuLabel.classList.remove("open")
				document.body.classList.remove("overflow-hidden");
				menuContainer.classList.remove("mobile-menu-show");
			}
		}

		setshowMobileMenu(show);	  
	}

	function hamburgerClicked(e: React.MouseEvent) {
		displayMobileMenu(!showMobileMenu);
 		e.preventDefault();		
	}

	return (
	<>
		<div id="menu-container" className="container-xxl">
			<div id="header" className="d-flex justify-content-between top-0 start-0 w-100 pt-3 pt-md-5 position-relative">
				<div id="mobile-menu" className="d-inline-block d-md-none">
					<button id="hamburger-menu" className="hamburger-menu position-relative" onClick={(e) => { hamburgerClicked(e)}}>
						<span className="hamburger hamburger-top position-absolute"></span>
						<span className="hamburger hamburger-bottom position-absolute"></span>
					</button>
				</div>

				<ul id="menu" className="d-none d-md-flex p-0 mb-0">
					<li>
						<div className="nav-top d-flex align-items-center">
							<Link to="/projects">SELECTED WORKS</Link>
						</div>						
					</li>
					<li>
						<div className="nav-top d-flex align-items-center">
							<Link to="/studio">INFO</Link>
						</div>						
					</li>
				</ul>

				<div id="logo" className="vienna">
					<a href="https://nikirollof.com/" title="STUDIO NIKI ROLLOF" rel="home">STUDIO NIKI&nbsp;ROLLOF</a>
					{/* <a href="https://niki.azurewebsites.net/" title="STUDIO NIKI ROLLOF" rel="home">STUDIO NIKI&nbsp;ROLLOF</a> */}
					{/* <a href="https://localhost:44358/" title="STUDIO NIKI ROLLOF" rel="home">STUDIO NIKI&nbsp;ROLLOF</a> */}
				</div>
			</div>

			{showMobileMenu &&
			<ul id="mobile-menu-links" className="d-block d-md-none position-absolute ps-0 top-50 start-50 w-100 mb-0">
				<li>
					<div className="nav-top d-flex flex-column align-items-center justify-content-center w-100">
						<Link to="/projects">SELECTED WORKS</Link>
					</div>						
				</li>
				<li>
					<div className="nav-top w-100">
						<Link className="py-4 d-flex align-items-center justify-content-center mb-2" to="/studio">INFO</Link>
					</div>						
				</li>
			</ul>}

		</div>

		{showMobileMenu && <div id="mobile-backdrop" className="d-block d-md-none position-fixed top-0 left-0 w-100 vh-100"/>}

   </>);
}
