import { parseName, Picture } from './Project';
import './project.css'

// Mårding
import s10_1 from '../content/images/Marding/marding3289.jpg'
import s10_2 from '../content/images/Marding/marding3288.jpg'
import s10_3 from '../content/images/Marding/marding3286.jpg'
import s10_4 from '../content/images/Marding/marding3287.jpg'
import s10_5 from '../content/images/Marding/marding3290.jpg'
import s10_6 from '../content/images/Marding/marding3291.jpg'
import s10_7 from '../content/images/Marding/marding3294.jpg'
import s10_8 from '../content/images/Marding/marding3292.jpg'

// Mårding webp
import s10_1w from '../content/images/Marding/marding3289.webp'
import s10_2w from '../content/images/Marding/marding3288.webp'
import s10_3w from '../content/images/Marding/marding3286.webp'
import s10_4w from '../content/images/Marding/marding3287.webp'
import s10_5w from '../content/images/Marding/marding3290.webp'
import s10_6w from '../content/images/Marding/marding3291.webp'
import s10_7w from '../content/images/Marding/marding3294.webp'
import s10_8w from '../content/images/Marding/marding3292.webp'

// Mårding Low Res
import s10_1_LR from '../content/images/Marding/marding3289_LR.jpg'
import s10_2_LR from '../content/images/Marding/marding3288_LR.jpg'
import s10_3_LR from '../content/images/Marding/marding3286_LR.jpg'
import s10_4_LR from '../content/images/Marding/marding3287_LR.jpg'
import s10_5_LR from '../content/images/Marding/marding3290_LR.jpg'
import s10_6_LR from '../content/images/Marding/marding3291_LR.jpg'
import s10_7_LR from '../content/images/Marding/marding3294_LR.jpg'
import s10_8_LR from '../content/images/Marding/marding3292_LR.jpg'

// Mårding Low Res webp
import s10_1_LRw from '../content/images/Marding/marding3289_LR.webp'
import s10_2_LRw from '../content/images/Marding/marding3288_LR.webp'
import s10_3_LRw from '../content/images/Marding/marding3286_LR.webp'
import s10_4_LRw from '../content/images/Marding/marding3287_LR.webp'
import s10_5_LRw from '../content/images/Marding/marding3290_LR.webp'
import s10_6_LRw from '../content/images/Marding/marding3291_LR.webp'
import s10_7_LRw from '../content/images/Marding/marding3294_LR.webp'
import s10_8_LRw from '../content/images/Marding/marding3292_LR.webp'

// Mårding plaeholder
import s10_1_ph from '../content/images/Marding/marding3289_ph.png'
import s10_2_ph from '../content/images/Marding/marding3288_ph.png'
import s10_3_ph from '../content/images/Marding/marding3286_ph.png'
import s10_4_ph from '../content/images/Marding/marding3287_ph.png'
import s10_5_ph from '../content/images/Marding/marding3290_ph.png'
import s10_6_ph from '../content/images/Marding/marding3291_ph.png'
import s10_7_ph from '../content/images/Marding/marding3294_ph.png'
import s10_8_ph from '../content/images/Marding/marding3292_ph.png'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function ProjectS10() {

	const slide = {
		images: [
		{ name: parseName(s10_1), highRes: s10_1, lowRes: s10_1_LR, highResW: s10_1w, lowResW: s10_1_LRw, placeholder: s10_1_ph, height: 1600, width: 2400 },
		{ name: parseName(s10_2), highRes: s10_2, lowRes: s10_2_LR, highResW: s10_2w, lowResW: s10_2_LRw, placeholder: s10_2_ph, height: 1600, width: 2400 },
		{ name: parseName(s10_3), highRes: s10_3, lowRes: s10_3_LR, highResW: s10_3w, lowResW: s10_3_LRw, placeholder: s10_3_ph, height: 1600, width: 2400 },
		{ name: parseName(s10_4), highRes: s10_4, lowRes: s10_4_LR, highResW: s10_4w, lowResW: s10_4_LRw, placeholder: s10_4_ph, height: 1600, width: 2400 },
		{ name: parseName(s10_5), highRes: s10_5, lowRes: s10_5_LR, highResW: s10_5w, lowResW: s10_5_LRw, placeholder: s10_5_ph, height: 2400, width: 1800 },
		{ name: parseName(s10_6), highRes: s10_6, lowRes: s10_6_LR, highResW: s10_6w, lowResW: s10_6_LRw, placeholder: s10_6_ph, height: 1600, width: 2400 },
		{ name: parseName(s10_7), highRes: s10_7, lowRes: s10_7_LR, highResW: s10_7w, lowResW: s10_7_LRw, placeholder: s10_7_ph, height: 2400, width: 1800 },
		{ name: parseName(s10_8), highRes: s10_8, lowRes: s10_8_LR, highResW: s10_8w, lowResW: s10_8_LRw, placeholder: s10_8_ph, height: 2400, width: 1800 }],
	copy: ["Commercial Space • Stockholm, 2021 • Photography by Magnus Mårding"]
	};

	let location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location])

	return (
		<div className="project-layout flex-layout overflow-hidden mb-5 pb-5">
			<div className="flex-layout container-fluid mt-md-4 px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[2]} useHighRes={true} className="" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[1]} useHighRes={true} className="" />
					</div>
				</div>
			</div>
			<div className="flex-layout container-xxl px-0 px-sm-3">
				<div className="row flex-column flex-md-row">
					<div className="col-12 col-md-6 mb-3 mb-md-0">
						<Picture image={slide.images[4]} useHighRes={false} className="" usePlaceHolder={true} />
					</div>
					<div className="col-12 col-md-6">
						<Picture image={slide.images[6]} useHighRes={false} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-fluid px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[3]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-xxl px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[7]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-fluid px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[0]} useHighRes={true} className=""  usePlaceHolder={true}/>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[5]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
		</div>
	);
}
