import { Link } from 'react-router-dom';
import { parseName, Picture } from './Project';

import './project.css'

// Mårding2
import img1_jpg from '../content/images/Marding2/marding3954.jpg'
import img1s_jpg from '../content/images/Marding2/marding3954_LR.jpg'
import img1_webp from '../content/images/Marding2/marding3954.webp'
import img1s_webp from '../content/images/Marding2/marding3954_LR.webp'
import img1_ph from '../content/images/Marding2/marding3954_ph.png'

import img2_jpg from '../content/images/Marding2/marding3958.jpg'
import img2s_jpg from '../content/images/Marding2/marding3958_LR.jpg'
import img2_webp from '../content/images/Marding2/marding3958.webp'
import img2s_webp from '../content/images/Marding2/marding3958_LR.webp'
import img2_ph from '../content/images/Marding2/marding3958_ph.png'

// Sibylle
import img3_jpg from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348.jpg'
import img3s_jpg from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348_LR.jpg'
import img3_webp from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348.webp'
import img3s_webp from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348_LR.webp'
import img3_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_348_ph.png'

import img4_jpg from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350.jpg'
import img4s_jpg from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350_LR.jpg'
import img4_webp from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350.webp'
import img4s_webp from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350_LR.webp'
import img4_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_350_ph.png'

import img7_jpg from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1.jpg'
import img7s_jpg from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1_LR.jpg'
import img7_webp from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1.webp'
import img7s_webp from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1_LR.webp'
import img7_ph from '../content/images/Sibyllegatan/2106_NR_Sibylleg_24_118_1_ph.png'

// Mårding1
import img5_jpg from '../content/images/Marding/marding3288.jpg'
import img5s_jpg from '../content/images/Marding/marding3288_LR.jpg'
import img5_webp from '../content/images/Marding/marding3288.webp'
import img5s_webp from '../content/images/Marding/marding3288_LR.webp'
import img5_ph from '../content/images/Marding/marding3288_ph.png'

import img6_jpg from '../content/images/Marding/marding3294.jpg'
import img6s_jpg from '../content/images/Marding/marding3294_LR.jpg'
import img6_webp from '../content/images/Marding/marding3294.webp'
import img6s_webp from '../content/images/Marding/marding3294_LR.webp'
import img6_ph from '../content/images/Marding/marding3294_ph.png'

export const Projects = () => {

  	// let p = useParams<IProject>();

	const slide = {
		images: [
		{ name: parseName(img1_jpg), highRes: img1_jpg, lowRes: img1s_jpg, highResW: img1_webp, lowResW: img1s_webp, placeholder: img1_ph, height: 2800, width: 2100 },
		{ name: parseName(img2_jpg), highRes: img2_jpg, lowRes: img2s_jpg, highResW: img2_webp, lowResW: img2s_webp, placeholder: img2_ph, height: 2800, width: 2100 },
		{ name: parseName(img3_jpg), highRes: img3_jpg, lowRes: img3s_jpg, highResW: img3_webp, lowResW: img3s_webp, placeholder: img3_ph, height: 2400, width: 1920 },
		{ name: parseName(img4_jpg), highRes: img4_jpg, lowRes: img4s_jpg, highResW: img4_webp, lowResW: img4s_webp, placeholder: img4_ph, height: 2400, width: 1920 },
		{ name: parseName(img5_jpg), highRes: img5_jpg, lowRes: img5s_jpg, highResW: img5_webp, lowResW: img5s_webp, placeholder: img5_ph, height: 1600, width: 2400 },
		{ name: parseName(img6_jpg), highRes: img6_jpg, lowRes: img6s_jpg, highResW: img6_webp, lowResW: img6s_webp, placeholder: img6_ph, height: 2400, width: 1800 },
		{ name: parseName(img7_jpg), highRes: img7_jpg, lowRes: img7s_jpg, highResW: img7_webp, lowResW: img7s_webp, placeholder: img7_ph, height: 2400, width: 1920 },
		],
	copy: ["Commercial Space • Stockholm, 2021 • Photography by Magnus Mårding"]
	};

	return (
		<div className="container-xxl overflow-hidden mt-md-4 px-0 px-sm-3 mb-5 pb-3">
			<div className="row flex-column flex-md-row">
				<div className="col-12 col-md-6">
					<Link to="/projects/snr">
						<Picture image={slide.images[0]} useHighRes={false} className="" />
						<div className="project-caption mt-2 mt-md-3 text-center">STUDIO SPACE</div>
						<div className="project-caption-2 mb-3 mb-md-4 text-center">STOCKHOLM</div>
					</Link>
				</div>
				<div className="col-12 col-md-6">
					<Link to="/projects/snr">
						<Picture image={slide.images[1]} useHighRes={false} className="" />
						<div className="project-caption mt-2 mt-md-3 text-center">STUDIO SPACE</div>
						<div className="project-caption-2 mb-3 mb-md-4 text-center">STOCKHOLM</div>
					</Link>
				</div>
			</div>

			<div className="row flex-column flex-md-row">
				<div className="col-12 col-md-5 m-auto">
					<Link to="/projects/s24">
						<Picture image={slide.images[2]} useHighRes={false} className="" />
						<div className="project-caption mt-2 mt-md-3 text-center">LF SHOWROOM</div>
						<div className="project-caption-2 mb-3 mb-md-4 text-center">STOCKHOLM</div>
					</Link>
				</div>
				<div className="col-12 col-md-7">
					<Link to="/projects/s24">
						<Picture image={slide.images[3]} useHighRes={false} className="" />
						<div className="project-caption mt-2 mt-md-3 text-center">LF SHOWROOM</div>
						<div className="project-caption-2 mb-3 mb-md-4 text-center">STOCKHOLM</div>
					</Link>
				</div>
			</div>

			<div className="row flex-column flex-md-row">
				<div className="col-12">
					<Link to="/projects/s10">
						<Picture image={slide.images[4]} useHighRes={true} className="" usePlaceHolder={true} />
						<div className="project-caption mt-2 mt-md-3 text-center">FC OFFICE</div>
						<div className="project-caption-2 mb-3 mb-md-4 text-center">STOCKHOLM</div>
					</Link>
				</div>
			</div>

			<div className="row flex-column flex-md-row">
				<div className="col-12 col-md-7">
					<Link to="/projects/s24">
						<Picture image={slide.images[6]} useHighRes={false} className="" usePlaceHolder={true} />
						<div className="project-caption mt-2 mt-md-3 text-center">LF SHOWROOM</div>
						<div className="project-caption-2 mb-3 mb-md-4 text-center">STOCKHOLM</div>
					</Link>
				</div>
				<div className="col-12 col-md-5 m-auto">
					<Link to="/projects/s10">
						<Picture image={slide.images[5]} useHighRes={false} className="" usePlaceHolder={true} />
						<div className="project-caption mt-2 mt-md-3 text-center">FC OFFICE</div>
						<div className="project-caption-2 mb-3 mb-md-4 text-center">STOCKHOLM</div>
					</Link>
				</div>
			</div>
		</div>
	);
}
