import React from 'react'
import generateStyleSheet from './generateStyleSheet'
import injectCss from './injectCss'

const elemId = 'backgroundImageSlider'

export interface BackgroundSliderProps {
	images: string[],
	duration?: number,
	transition?: number
}

export function BackgroundImageSlider(props: BackgroundSliderProps) {
	const duration =	props.duration || 10;
	const transition =	props.transition || 2;


	React.useEffect(() => {
		injectCss(
			generateStyleSheet({
				imagesCount: props.images.length,
				duration,
				transition
			}),
			elemId
		)
  	})

  	return (
		<div id={elemId}>
			{props.images.map((img, key) =>
				<figure key={key}
					style={{
						backgroundImage: `url(${img})`,
						animationDelay: `${(duration + transition) * key}s`
					}}
				/>
			)}
		</div>
	)
}