import { parseName, Picture } from './Project';
import './project.css'

// Mårding 2
import img1_jpg from '../content/images/Marding2/marding3953.jpg'
import img2_jpg from '../content/images/Marding2/marding3954.jpg'
import img3_jpg from '../content/images/Marding2/marding3955.jpg'
import img4_jpg from '../content/images/Marding2/marding3956.jpg'
import img5_jpg from '../content/images/Marding2/marding3957.jpg'
import img6_jpg from '../content/images/Marding2/marding3958.jpg'
import img7_jpg from '../content/images/Marding2/marding3959.jpg'
import img8_jpg from '../content/images/Marding2/marding3960.jpg'
import img9_jpg from '../content/images/Marding2/marding3961.jpg'
import img10_jpg from '../content/images/Marding2/marding3962.jpg'
import img11_jpg from '../content/images/Marding2/marding3963.jpg'

// Mårding 2 webp
import img1_webp from '../content/images/Marding2/marding3953.webp'
import img2_webp from '../content/images/Marding2/marding3954.webp'
import img3_webp from '../content/images/Marding2/marding3955.webp'
import img4_webp from '../content/images/Marding2/marding3956.webp'
import img5_webp from '../content/images/Marding2/marding3957.webp'
import img6_webp from '../content/images/Marding2/marding3958.webp'
import img7_webp from '../content/images/Marding2/marding3959.webp'
import img8_webp from '../content/images/Marding2/marding3960.webp'
import img9_webp from '../content/images/Marding2/marding3961.webp'
import img10_webp from '../content/images/Marding2/marding3962.webp'
import img11_webp from '../content/images/Marding2/marding3963.webp'

// Mårding 2 Low Res
import img1s_jpg from '../content/images/Marding2/marding3953_LR.jpg'
import img2s_jpg from '../content/images/Marding2/marding3954_LR.jpg'
import img3s_jpg from '../content/images/Marding2/marding3955_LR.jpg'
import img4s_jpg from '../content/images/Marding2/marding3956_LR.jpg'
import img5s_jpg from '../content/images/Marding2/marding3957_LR.jpg'
import img6s_jpg from '../content/images/Marding2/marding3958_LR.jpg'
import img7s_jpg from '../content/images/Marding2/marding3959_LR.jpg'
import img8s_jpg from '../content/images/Marding2/marding3960_LR.jpg'
import img9s_jpg from '../content/images/Marding2/marding3961_LR.jpg'
import img10s_jpg from '../content/images/Marding2/marding3962_LR.jpg'
import img11s_jpg from '../content/images/Marding2/marding3963_LR.jpg'

// Mårding 2 Low Res webp
import img1s_webp from '../content/images/Marding2/marding3953_LR.webp'
import img2s_webp from '../content/images/Marding2/marding3954_LR.webp'
import img3s_webp from '../content/images/Marding2/marding3955_LR.webp'
import img4s_webp from '../content/images/Marding2/marding3956_LR.webp'
import img5s_webp from '../content/images/Marding2/marding3957_LR.webp'
import img6s_webp from '../content/images/Marding2/marding3958_LR.webp'
import img7s_webp from '../content/images/Marding2/marding3959_LR.webp'
import img8s_webp from '../content/images/Marding2/marding3960_LR.webp'
import img9s_webp from '../content/images/Marding2/marding3961_LR.webp'
import img10s_webp from '../content/images/Marding2/marding3962_LR.webp'
import img11s_webp from '../content/images/Marding2/marding3963_LR.webp'

// Mårding 2 placeholder
import img1s_ph from '../content/images/Marding2/marding3953_ph.png'
import img2s_ph from '../content/images/Marding2/marding3954_ph.png'
import img3s_ph from '../content/images/Marding2/marding3955_ph.png'
import img4s_ph from '../content/images/Marding2/marding3956_ph.png'
import img5s_ph from '../content/images/Marding2/marding3957_ph.png'
import img6s_ph from '../content/images/Marding2/marding3958_ph.png'
import img7s_ph from '../content/images/Marding2/marding3959_ph.png'
import img8s_ph from '../content/images/Marding2/marding3960_ph.png'
import img9s_ph from '../content/images/Marding2/marding3961_ph.png'
import img10s_ph from '../content/images/Marding2/marding3962_ph.png'
import img11s_ph from '../content/images/Marding2/marding3963_ph.png'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function ProjectSNR() {

	const slide = {
		images: [
		{ name: parseName(img1_jpg), highRes: img1_jpg, lowRes: img1s_jpg, highResW: img1_webp, lowResW: img1s_webp, placeholder: img1s_ph, height: 2800, width: 2100 },
		{ name: parseName(img2_jpg), highRes: img2_jpg, lowRes: img2s_jpg, highResW: img2_webp, lowResW: img2s_webp, placeholder: img2s_ph, height: 2800, width: 2100 },
		{ name: parseName(img3_jpg), highRes: img3_jpg, lowRes: img3s_jpg, highResW: img3_webp, lowResW: img3s_webp, placeholder: img3s_ph, height: 2800, width: 2099 },
		{ name: parseName(img4_jpg), highRes: img4_jpg, lowRes: img4s_jpg, highResW: img4_webp, lowResW: img4s_webp, placeholder: img4s_ph, height: 1867, width: 2800 },
		{ name: parseName(img5_jpg), highRes: img5_jpg, lowRes: img5s_jpg, highResW: img5_webp, lowResW: img5s_webp, placeholder: img5s_ph, height: 2800, width: 2100 },
		{ name: parseName(img6_jpg), highRes: img6_jpg, lowRes: img6s_jpg, highResW: img6_webp, lowResW: img6s_webp, placeholder: img6s_ph, height: 2800, width: 2100 },
		{ name: parseName(img7_jpg), highRes: img7_jpg, lowRes: img7s_jpg, highResW: img7_webp, lowResW: img7s_webp, placeholder: img7s_ph, height: 1867, width: 2800 },
		{ name: parseName(img8_jpg), highRes: img8_jpg, lowRes: img8s_jpg, highResW: img8_webp, lowResW: img8s_webp, placeholder: img8s_ph, height: 2800, width: 2100 },
		{ name: parseName(img9_jpg), highRes: img9_jpg, lowRes: img9s_jpg, highResW: img9_webp, lowResW: img9s_webp, placeholder: img9s_ph, height: 1867, width: 2800 },
		{ name: parseName(img10_jpg), highRes: img10_jpg, lowRes: img10s_jpg, highResW: img10_webp, lowResW: img10s_webp, placeholder: img10s_ph, height: 2800, width: 2100 },
		{ name: parseName(img11_jpg), highRes: img11_jpg, lowRes: img11s_jpg, highResW: img11_webp, lowResW: img11s_webp, placeholder: img11s_ph, height: 2800, width: 2100 },
		],
		copy: ["Commercial Space • Stockholm, 2022 • Photography by Magnus Mårding"]
	};

	let location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location])

	return (
		<div className="project-layout flex-layout overflow-hidden mb-5 pb-5">
			<div className="flex-layout container-fluid mt-md-4 px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[3]} useHighRes={true} className="" />
					</div>
				</div>
			</div>
			<div className="flex-layout container-xxl px-0 px-sm-3">
				<div className="row flex-column flex-md-row">
					<div className="col-12 col-md-6 mb-3 mb-md-0">
						<Picture image={slide.images[5]} useHighRes={false} className="" />
					</div>
					<div className="col-12 col-md-6">
						<Picture image={slide.images[9]} useHighRes={false} className="" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[7]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[4]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[1]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-fluid px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[8]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-xxl px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[10]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[2]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
			<div className="flex-layout container-fluid px-0 px-sm-3">
				<div className="row">
					<div className="col-12">
						<Picture image={slide.images[6]} useHighRes={true} className="" usePlaceHolder={true} />
					</div>
				</div>
			</div>
		</div>
	);
}
