export function FadeOut(props: any)  {

  return (
	<div className="maxi fade-out-X">
		<div className="centered">
			{props.children}
		</div>
	</div>
  );
}
